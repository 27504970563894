<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="车队简称">
              <a-input v-model="queryParam['%short_name']"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="车队类型">
              <a-select
                showSearch
                allowClear
                placeholder="请选择车队类型"
                style="width: 100%"
                v-model="queryParam.type"
              >
                <a-select-option v-for="item in fleetTypeMap" :key="item.value">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
      <a-button type="danger" icon="delete" @click="handleDelete('all')" :disabled="!this.selectedRowKeys.length">删除</a-button>
    </div>

    <s-table ref="table" size="default"
             rowKey="id" :columns="columns"
             :data="loadData" :scroll="{ x: 1000 }"
             :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
             @dblclick="handleEdit">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="type" slot-scope="text">
        {{ fleetTypeMap[text] && fleetTypeMap[text].name }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="handleEdit(record, index)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete('row', record)">删除</a>
        </template>
      </span>
    </s-table>

    <a-modal
      :visible="formVisible" :confirmLoading="confirmLoading"
      :title="modalFlag === 'create' ? '创建车队' : '编辑车队'"
      :width="720" @cancel="formVisible = false"
      @ok="handleSubmit"
    >
      <a-form-model layout="vertical" :model="form"
                    :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-col :span="8">
            <a-form-model-item label="车队简称" prop="short_name">
              <a-input v-model="form.short_name" @change="handleNameChange" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="车队简码">
              <a-input v-model="form.abbr" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="车队全称">
              <a-input v-model="form.full_name" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="8">
            <a-form-model-item label="EIR代码">
              <a-input v-model="form.eir_num" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="车队类型">
              <a-select
                showSearch
                allowClear
                placeholder="请选择车队类型"
                style="width: 100%"
                v-model="form.type"
              >
                <template v-for="item in fleetTypeMap">
                  <a-select-option :key="item.value">{{ item.name }}</a-select-option>
                </template>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="联系人">
              <a-input v-model="form.contact" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="8">
            <a-form-model-item label="固定电话">
              <a-input v-model="form.tel_num" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="手机号">
              <a-input v-model="form.mobile" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="电子邮箱">
              <a-input v-model="form.email" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="8">
            <a-form-model-item label="qq号">
              <a-input v-model="form.qq" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="微信">
              <a-input v-model="form.wechat" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="扣税税率">
              <a-input v-model="form.tax_rate" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item label="地址">
          <a-input v-model="form.address" />
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea v-model="form.remark" placeholder="" :rows="3" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getFirstLetter } from '@/utils/util'
import { getMetaInfoPage, deleteMetaTypeInfo, createMetaTypeInfo, updateMetaTypeInfo } from '@/api/wms/meta'
export default {
  components: {
    STable
  },
   data() {
    return {
      queryParam: {},
      fleetTypeMap: {
        0: { value: 0, name: '其它' },
        1: { value: 1, name: '自有车队' },
        2: { value: 2, name: '外派车队' }
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 30
        },
        {
          title: '车队简称',
          dataIndex: 'short_name',
          width: 80
        },
        {
          title: '车队简码',
          dataIndex: 'abbr',
          width: 80
        },
        {
          title: '车队全称',
          dataIndex: 'full_name',
          width: 120
        },
        {
          title: 'EIR代码',
          dataIndex: 'eir_num',
          width: 80
        },
        {
          title: '车队类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          width: 80
        },
        {
          title: '联系人',
          dataIndex: 'contact',
          width: 80
        },
        {
          title: '固定电话',
          dataIndex: 'tel_num',
          width: 100
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          width: 120
        },
        {
          title: '扣税税率',
          dataIndex: 'tax_rate',
          width: 80
        },
        {
          title: '地址',
          dataIndex: 'address',
          width: 120
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      loadData: parameter => {
        return getMetaInfoPage('fleet', Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      form: {
        short_name: '',
        abbr: '',
        full_name: '',
        eir_num: '',
        type: null,
        contact: '',
        tel_num: '',
        mobile: '',
        email: '',
        qq: '',
        wechat: '',
        tax_rate: '',
        address: '',
        remark: ''
      },
      rules: {
        short_name: [
          { required: true, message: '请输入车队简称', trigger: 'blur' }
        ]
      },
      formVisible: false,
      confirmLoading: false,
      modalFlag: 'create'
    }
  },
  methods: {
    handleCreate() {
      this.modalFlag = 'create'
      this.form = {
        short_name: '',
        abbr: '',
        full_name: '',
        eir_num: '',
        type: null,
        contact: '',
        tel_num: '',
        mobile: '',
        email: '',
        qq: '',
        wechat: '',
        tax_rate: '',
        address: '',
        remark: ''
      }
      this.formVisible = true
      this.$nextTick(_ => {
        this.$refs.form.resetFields();
      })
    },
    handleNameChange() {
      this.form.abbr = getFirstLetter(this.form.short_name)
    },
    handleEdit(record) {
      this.modalFlag = 'update'
      this.formVisible = true
      this.form = record
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteMetaTypeInfo('fleet', { ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = [];
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let func = createMetaTypeInfo
          if (this.modalFlag === 'update') {
            func = updateMetaTypeInfo
          }
          func('fleet', this.form).then(_ => {
              this.confirmLoading = false
              this.formVisible = false
              this.$notification['success']({
                message: '提示',
                description: this.modalFlag === 'update' ? '编辑成功' : '创建成功.'
              })
              if (this.modalFlag === 'create') {
                this.$refs.table.refresh(true)
              }
            }).catch(_ => {
              this.formVisible = false
              this.confirmLoading = false
            })
        } else {
          return false;
        }
      });
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>
